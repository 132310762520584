/* 
  parametri di personalizzazione:
  stip_color
  stip_title
  stip_subtitle
  stip_privacy
  stip_terms
  stip_height

  parametri necessari:
  stip_id

  parametri opzionali per brico:
  store_id
*/

import './index.scss'
import $ from "jquery";
import uuid from 'uuid'
import Cookies from 'js-cookie'
import { catchError, delayWhen, retryWhen, tap, map } from 'rxjs/operators';
import * as Rx from 'rxjs/Rx';
import { Subject } from 'rxjs/Rx';
import { webSocket } from 'rxjs/webSocket';
import { Observable, timer } from 'rxjs';
// import Swiper JS
import Swiper from './swiper/index';
import 'regenerator-runtime/runtime';
/* import 'swiper/swiper-bundle.css'; */

// showdown
import showdown from 'showdown';
const converter = new showdown.Converter();

let url_string = window.location.href;

function getUrlParameter(location, name) {
  name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
  var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
  var results = regex.exec(location);
  return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};

$.noConflict();
document.addEventListener("DOMContentLoaded", function (event) {
  console.log("here!")

  let this_script = $("script[src*='index.bundle.js']");

  let stip_user_id
  let stip_id = (getUrlParameter(url_string, "stip_id")) ? getUrlParameter(url_string, "stip_id") : this_script.attr('stip_id')
  // set dei coockies
  if (localStorage.getItem('stip_user_id_' + stip_id)) { // utente esistente
    stip_user_id = localStorage.getItem('stip_user_id_' + stip_id)
  } else { // nuovo utente
    stip_user_id = uuid.v4()
    localStorage.setItem('stip_user_id_' + stip_id, stip_user_id);
  }

  let assistant_id = (getUrlParameter(url_string, "assistant_id")) ? getUrlParameter(url_string, "assistant_id") : this_script.attr('assistant_id')
  let stip_company_name = (getUrlParameter(url_string, "stip_company_name")) ? getUrlParameter(url_string, "stip_company_name") : this_script.attr('stip_company_name')
  let store_id = (getUrlParameter(url_string, "store_id")) ? getUrlParameter(url_string, "store_id") : this_script.attr('store_id')
  let id_adv = (getUrlParameter(url_string, "id_adv")) ? getUrlParameter(url_string, "id_adv") : this_script.attr('id_adv')
  let stip_color = (getUrlParameter(url_string, "stip_color")) ? getUrlParameter(url_string, "stip_color") : ((this_script.attr('stip_color')) ? this_script.attr('stip_color') : "rgb(51, 151, 211)")
  let stip_secondary_color = (getUrlParameter(url_string, "stip_secondary_color")) ? getUrlParameter(url_string, "stip_secondary_color") : this_script.attr('stip_secondary_color')
  let stip_bot_box_color = (getUrlParameter(url_string, "stip_bot_box_color")) ? getUrlParameter(url_string, "stip_bot_box_color") : this_script.attr('stip_bot_box_color')
  let stip_bot_text_color = (getUrlParameter(url_string, "stip_bot_text_color")) ? getUrlParameter(url_string, "stip_bot_text_color") : this_script.attr('stip_bot_text_color')
  let stip_user_text_color = (getUrlParameter(url_string, "stip_user_text_color")) ? getUrlParameter(url_string, "stip_user_text_color") : this_script.attr('stip_user_text_color')
  let stip_icon_color = (getUrlParameter(url_string, "stip_icon_color")) ? getUrlParameter(url_string, "stip_icon_color") : this_script.attr('stip_icon_color')
  let stip_chat_icon = (getUrlParameter(url_string, "stip_chat_icon") || this_script.attr('stip_chat_icon')) ? ('<img src="' + (getUrlParameter(url_string, "stip_chat_icon") || this_script.attr('stip_chat_icon')) + '" class="stip_chat-icon" style="width:40px">') : ('<i class="material-icons stip_chat-icon" style="color:' + stip_icon_color + '"> chat </i>');
  let stip_subtitle = ((getUrlParameter(url_string, "stip_subtitle")) ? getUrlParameter(url_string, "stip_subtitle") : ((this_script.attr('stip_subtitle')) ? this_script.attr('stip_subtitle') : "Assistente Virtuale"))
  let stip_restart_text = ((getUrlParameter(url_string, "stip_restart_text")) ? getUrlParameter(url_string, "stip_restart_text") : ((this_script.attr('stip_restart_text')) ? this_script.attr('stip_restart_text') : "ricomincia"))
  let stip_terms = (getUrlParameter(url_string, "stip_terms")) ? getUrlParameter(url_string, "stip_terms") : this_script.attr('stip_terms')
  let stip_height = (getUrlParameter(url_string, "stip_height")) ? getUrlParameter(url_string, "stip_height") : this_script.attr('stip_height')
  let stip_avatar_svg = `
    <svg id="stip_avatar_svg" class="stip_avatar" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" width="174.4px"
    height="136px" viewBox="0 0 174.4 136" style="enable-background:new 0 0 174.4 136;" xml:space="preserve">
      <style type="text/css">
        .st0{fill:` + stip_color + `;}
        .st1{fill:#BBFDFF;}
        .st2{fill:url(#SVGID_1_);}
        .st3{fill:#5AFFBC;}
        .st4{fill:url(#SVGID_2_);}
      </style>
      <g>
        <path class="st0" d="M171.8,54.8l-1-0.5v-3.8c0-3.2-1.8-6.1-4.7-7.5l-9.8-4.8C143.6,15.6,117,0,86.1,0c-31.5,0-58.7,16.3-71,39.7
          L8.3,43c-2.9,1.4-4.7,4.3-4.7,7.5v3.8l-1,0.5C1,55.6,0,57.2,0,59v20.8c0,1.8,1,3.4,2.6,4.1l1,0.5v3.8c0,3.2,1.8,6.1,4.7,7.5
          l8.9,4.4c13.2,21.4,39.1,36,68.9,36c29.1,0,54.5-13.9,67.9-34.4l12.2-5.9c2.9-1.4,4.7-4.3,4.7-7.5v-3.8l1-0.5
          c1.6-0.8,2.6-2.4,2.6-4.1V59C174.4,57.2,173.4,55.6,171.8,54.8z"/>
        <path class="st1" d="M143.3,73.4c0,2.9-2,5.4-4.8,6.1c-7.9,1.8-25.5,4.9-52.2,4.9c-26.9,0-44.4-3.1-52.2-4.9
          c-2.8-0.6-4.8-3.2-4.8-6.1v-31c0-3,2.2-5.6,5.1-6.1c7.8-1.3,24.8-3.4,52-3.4c26.7,0,43.8,2.1,51.7,3.4c3,0.5,5.2,3.1,5.2,6.1V73.4z
          "/>
        <linearGradient id="SVGID_1_" gradientUnits="userSpaceOnUse" x1="86.2487" y1="82.698" x2="86.2487" y2="34.4137">
          <stop  offset="0" style="stop-color:#000000"/>
          <stop  offset="1" style="stop-color:#000000"/>
        </linearGradient>
        <path class="st2" d="M141.5,72.4c0,2.7-1.9,5.1-4.7,5.7c-7.7,1.7-24.7,4.6-50.6,4.6c-26.1,0-43.1-2.9-50.7-4.6
          c-2.7-0.6-4.6-3-4.6-5.7V43.3c0-2.9,2.1-5.3,5-5.7c7.6-1.2,24.1-3.1,50.4-3.1c25.9,0,42.5,2,50.2,3.2c2.9,0.4,5,2.9,5,5.7V72.4z"/>
        <path class="st3" d="M62.8,65.6h-7.9c-2.7,0-4.8-2.2-4.8-4.8v-7.9c0-2.7,2.2-4.8,4.8-4.8h7.9c2.7,0,4.8,2.2,4.8,4.8v7.9
          C67.6,63.5,65.5,65.6,62.8,65.6z"/>
        <path class="st3" d="M117.6,65.6h-7.9c-2.7,0-4.8-2.2-4.8-4.8v-7.9c0-2.7,2.2-4.8,4.8-4.8h7.9c2.7,0,4.8,2.2,4.8,4.8v7.9
          C122.5,63.5,120.3,65.6,117.6,65.6z"/>
      </g>
    </svg>
  `
  let stip_background = (getUrlParameter(url_string, "stip_background")) ? getUrlParameter(url_string, "stip_background") : this_script.attr('stip_background')
  let stip_avatar = ((getUrlParameter(url_string, "avatar")) ? ("<div class='stip_avatar' style='background: url(" + getUrlParameter(url_string, "avatar") + ") no-repeat center center; background-size: contain;'></div>") : ((this_script.attr('avatar')) ? ("<div class='stip_avatar' style='background: url(" + this_script.attr('avatar') + ") no-repeat center center; background-size: contain;'></div>") : stip_avatar_svg))
  let stip_color_header = (getUrlParameter(url_string, "stip_color_header")) ? getUrlParameter(url_string, "stip_color_header") : this_script.attr('stip_color_header')
  let stip_avatar_header = ((getUrlParameter(url_string, "avatar_header")) ? ("<div class='stip_avatar' style='background: url(" + getUrlParameter(url_string, "avatar_header") + ") no-repeat center center; background-size: contain;'></div>") : ((this_script.attr('avatar_header')) ? ("<div class='stip_avatar' style='background: url(" + this_script.attr('avatar_header') + ") no-repeat center center; background-size: contain;'></div>") : ''))
  let stip_hide_chat = (getUrlParameter(url_string, "stip_hide_chat")) ? getUrlParameter(url_string, "stip_hide_chat") : this_script.attr('stip_hide_chat')
  let stip_welcome_message = (getUrlParameter(url_string, "stip_welcome_message") || this_script.attr("stip_welcome_message")) ? '<div class="stip_welcome-message"><p>Ciao! Se ti serve aiuto sono qui.</p></div>' : ""
  let input_type = ""
  let stip_companyname = ""
  let basePath = ""
  let token = ""
  let chat_lang = "it"
  let cookie_lang = (getUrlParameter(url_string, "cookie_lang_param")) ? getUrlParameter(url_string, "cookie_lang_param") : this_script.attr('cookie_lang_param')

  // variabili per creare un'id html diverso per ogni messagio ricevuto
  let chat_id_txt = "input"
  let chat_counter = 0
  let chat_id
  let end_thread = false
  let required = false
  let countries = []
  let first_message = true
  let cookie_previous_message = []
  let push_cookie = true
  let chat_show

  // se in localStorage presente stip_user_id_ + stip_id e #stip_card-body presente non fare nulla per evitare duplicati (problema BRICO)
  if (localStorage.getItem('stip_user_id_' + stip_id) && $(".stip_card-body").length) return


  if (getUrlParameter(url_string, "stip_chat-fixed") == true || this_script.attr('stip_chat-fixed') == true) {
    if (!$("#stip_chat").length) {
      $("body").append("<div id='stip_chat'></div>")
      $("#stip_chat").hide()
    }
  }

  // html da appendere al DOM
  !$(".stip_card").length && $("#stip_chat").append(` 
    <div class="stip_card stip-d-flex stip-flex-column stip-h-100" style="position: relative;">
      <div id="stip_privacy-modal">
        <div class="stip_privacy-modal-content">
          <div class="stip-d-flex stip-align-items-center" style="margin-bottom:10px; justify-content:flex-end">
            <i class="material-icons">close</i>
          </div>
          <iframe id="stip_privacy-iframe" src="" frameBorder="0"></iframe>
        </div>
      </div>
      ` + stip_welcome_message + `
      <div id="stip_chat_icon_box">` + stip_chat_icon + `</div>
      <div class="stip_card-header stip-align-items-center">
        <div style="display: flex; align-items: center">
          <div id="stip_avatar_header_box">` + stip_avatar_header + `</div>
          <div class="stip-ml-3">
            <p class="stip_p stip_text-white stip-m-0" id="stip_title"><b id="stip_company-name"></b></p>
            <p class="stip_p stip_text-white stip-m-0" id="stip_subtitle">` + stip_subtitle + `</p>
          </div>
        </div>
        <div class="stip-d-flex stip-align-items-center" style="gap:10px">
          <i class="material-icons" id="stip_privacy-btn">info</i>
          <i class="material-icons" id="stip_restart-button">refresh</i>
          <i class="material-icons stip_close-btn">close</i>
        </div>
      </div>
      <div class="stip_card-body stip-flex-grow-1 stip-flex-column">
        
      </div>
      <div class="stip_card-footer stip-justify-content-end">
        <div id="stip_is-writing" style="display:none">
          <div class="snippet" data-title="dot-flashing">
            <div class="stage">
              <div class="dot-flashing"></div>
            </div>
          </div>
        </div>
        <p class="stip_label" id="stip_is-connecting">In connessione...</p>
        <button type="button" id="stip_start-btn" class="stip-btn stip_p stip_action-button">inizia la conversazione</button>
        <form id="stip_chat_form" class="stip-flex-grow-1">
          <div class="stip-d-flex" style="flex-direction: column;">
            <div id="stip_input-append-user" class="stip-flex-grow-1 stip-d-flex stip-align-items-center">
              <div class="stip-form-group stip-flex-grow-1 stip-m-0" id="stip-form-group">
                <div id="stip_city-suggestion" class="stip_menu">
                
                </div>
                <div class="stip-d-flex stip-align-items-center">
                  <input type="file" class="stip-form-control" style="display:none" id="stip_input-file">
                  <input type="text" class="stip-form-control stip_input stip-flex-grow-1" placeholder="scrivi qui ..." required></input>
                  <button id="stip_attach-icon" type="button" style="display: none;" class="stip-btn stip_action-button"><span class="material-icons" style="vertical-align:bottom" for="stip_input-file">
                    attach_file
                  </span></button>
                </div>
                <!--<textarea rows="2" minlength="0" type="text" class="stip-form-control stip_input stip-flex-grow-1" placeholder="scrivi qui ..." required></textarea>-->
              </div>
              <button type="submit" class="stip-btn stip_send-box stip-ml-2"><i class="stip_send-icon material-icons stip_send-btn">send</i></button>
            </div>
            <div class="stip-d-flex stip-mt-2" style="justify-content: space-between;">
            <!--<button type="button" id="stip_restart-button" class="stip-btn">` + stip_restart_text + `</button>-->
              <button type="button" id="stip_skip-button" class="stip-btn">Salta</button>
            </div>
            </div>
        </form>
        <!--<div class="stip-d-flex stip-align-items-center stip-justify-content-center">
          <label class="stip_label" style="margin-right: 5px">Powered by</label>
          <a href="https://stip.io" target="_blank"><img style="vertical-align:middle" src="https://stip.io/src/img/logoColor.svg" width="25"></a>
        </div>-->
      </div>
      <div id="stip_restart-modal" class="stip_restart-modal">
        <div class="stip_restart-modal-content">
          <p class="stip_h2" id="stip_restart-modal-title">Sei sicuro di voler ricominciare?</p>
          <button type="button" id="stip_yesrestart-button" class="stip-btn stip_p stip_action-button">Si</button>
          <button type="button" id="stip_norestart-button" class="stip-btn stip_p stip_action-button stip-ml-2">No</button>
        </div>
      </div>
    </div>
  `)
  // modale offline
  !$("#stip_offline-modal").length && $("#stip_chat").after(`
    <div id="stip_offline-modal" class="stip_offline-modal">
      <div class="stip_offline-modal-content">
        <p class="stip_h2" style="margin:0" id="stip_offline-modal-title">I nostri operatori non sono disponibili.</p>
        <p class="stip_p">La chat è disponibile dalle 9:00 alle 18:00</p>
        <button type="button" id="stip_offline-button" class="stip-btn stip_p stip_action-button">Chiudi</button>
      </div>
    </div>
  `)

  // get della risposta dell'ai in streaming
  async function getStreamData(data_to_send) {
    const response = await fetch('https://prod.ai.stipworld.com/openai_assistants/stream_conversation', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "backend_id": stip_user_id,
        "body": data_to_send.messaging[0].message.text
      })
    })
    const reader = response.body.pipeThrough(new TextDecoderStream()).getReader();

    let result = ""
    let isFirst = true
    while (true) {
      const { value, done } = await reader.read();

      // remove "Received file_search" from the response
      result += (value ? value.replaceAll("Received ", "").replaceAll("file_search", "").replaceAll(/【[^】]*】/g, '') : "")

      isFirst ? receiveMessage({
        text: {
          message: {
            text: result,
            is_from_customer: false
          }
        }
      }) : $("#" + chat_id).html(converter.makeHtml(result))

      isFirst = false
      if (done) {
        // replace last item of cookie_previous_message with the new message
        cookie_previous_message.pop()
        cookie_previous_message.push({
          text: {
            message: {
              text: converter.makeHtml(result),
              is_from_customer: false
            }
          }
        })
        localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))

        break
      };
    }
  }

  // chiamata di invio messaggio e ricezione risposta
  function sendMessage(data_to_send) {
    if (assistant_id) { // se chat AI
      receiveMessage({ // messaggio per loading
        text: {
          message: {
            is_typing: true
          }
        }
      })
      // push user message to local storage previous message
      cookie_previous_message.push({
        text: {
          message: {
            text: data_to_send.messaging[0].message.text,
            is_from_customer: true
          }
        }
      })
      localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
      getStreamData(data_to_send)
    } else {
      if (ws_connected) {
        $.ajax({
          url: "https://bot.stipworld.com/api/web/webhook/",
          type: 'POST',
          crossDomain: true,
          contentType: 'application/json',
          data: JSON.stringify(data_to_send),
          success: function (res) {
            //console.log(res)
            $('#stip_is-connecting').hide()
          },
          error: function (err) {
            console.log(err)
            // se va in errore riprova 
            setTimeout(function () {
              sendMessage(data_to_send)
            }, 100)
          }
        })
      } else {
        console.log("ws disconnessa o in connessione")
        $('#stip_is-connecting').show()
        setTimeout(function () {
          sendMessage(data_to_send)
        }, 50)
      }
    }
  }

  let send_last_message = false
  let previous_message_length = 0

  // receive a message though the Websocket from the server
  function receiveMessage(msg) {

    let is_typing = msg.text.message.is_typing ? msg.text.message.is_typing : false

    if (is_typing) {
      $("#stip_is-writing").show()
    } else {
      $("#stip_is-writing").hide()

      if (send_last_message && msg.text.message.is_from_customer) {
        cookie_previous_message.push(msg) // push dei messaggi precedenti nell'array
        localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
        send_last_message = false
        return
      } else {
        //$(".stip_choice-btn").remove()
      }

      if ((!msg.text.message.previous_message && msg.text.message.previous_message != undefined) || !msg.text.message.is_from_customer) {
        if (push_cookie && !msg.text.message.root) {
          cookie_previous_message.push(msg) // push dei messaggi precedenti nell'array
          localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
        } else if (msg.text.message.root) {
          cookie_previous_message = []
          cookie_previous_message.push(msg) // push del messaggio della root
          localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
        } else {
          push_cookie = true
        }

        end_thread = msg.text.message.end_thread
        if (end_thread && !msg.text.message.input_expected) {
          $("#stip_attach-icon").show()
        } else {
          $("#stip_attach-icon").hide()
        }
        required = (msg.text.message.required != undefined) ? msg.text.message.required : true
        if (required) {
          $("#stip_skip-button").hide()
        } else {
          $("#stip_skip-button").show()
        }

        // hide e show notifica
        if (localStorage.getItem('stip_chat-open_' + stip_id) == "open") {
          $(".stip_notification").hide()
        } else if (first_message) {
          $(".stip_notification").hide()
          first_message = false
        } else {
          if (getUrlParameter(url_string, "stip_chat-fixed") == true) {
            $("#stip_chat").append(` 
              <div class="stip_notification"></div>
            `)
          }
        }

        // creazione un'id html diverso per ogni messagio ricevuto
        chat_counter += 1
        chat_id = chat_id_txt + chat_counter

        let str = ""
        if (msg.text.message.attachment) {
          str = "<p class='stip-m-0 stip_p'>" + msg.text.message.attachment.payload.text.replaceAll(/(?:\r\n|\r|\n)/g, '<br>') + "</p>"
        } else if (msg.text.message.attachments) { // se i messaggi in arrivo sono allegati
          if (msg.text.message.text && msg.text.message.attachments.length == 0) {
            str = "<p class='stip-m-0 stip_p'>" + msg.text.message.text.replace(/(?:\r\n|\r|\n)/g, '<br>') + "</p>";
          } else {
            msg.text.message.attachments.forEach(function (el) {
              if (el.attachment_type == "image") {
                str = (`
                  <img src=` + el.url + ` style="cursor: pointer;" class="stip_lightbox-img" id="` + el.id + `" width="100">
                  <div id="modal` + el.id + `" class="stip_lightbox" style="display: none">
                    <div class="stip_lightbox-dialog">
                      <div class="stip_lightbox-content">
                        <i class="material-icons stip_lightbox-close" style="color: ` + stip_color + `" id="` + el.id + `">close</i>
                        <img src=` + el.url + ` style="max-width: 100%">
                      </div>
                    </div>
                  </div>
                `)
              } else if (el.attachment_type == "file") {
                str = '<a href=' + el.url + ' target="_blank">Allegato</a>'
              } else if (el.attachment_type == "video") {
                str = '<video width="320" height="240" controls><source src=' + el.url + ' type="video/mp4"><source src=' + el.url + ' type="video/ogg">Your browser does not support the video tag.</video>'
              } else if (el.attachment_type == "audio") {
                str = '<audio controls><source src=' + el.url + ' type="audio/mpeg"><source src=' + el.url + ' type="audio/ogg">Your browser does not support the audio tag.</audio>'
              }
            })
          }
        } else { // se i messaggi sono stringhe
          str = "<p class='stip-m-0 stip_p'>" + msg.text.message.text.replace(/(?:\r\n|\r|\n)/g, '<br>') + "</p>";
        }

        // if message contain links and is not already inside and <a> tag add a tag with target blank
        if (str.includes("http") && !str.includes("<a") && !str.includes("<img") && !str.includes("<audio") && !str.includes("<video")) {
          // urls that match http or https and stop on space or < 
          let urls = str.match(/(http|https):\/\/[^\s<>]+/g)
          // remove duplicates
          urls = urls.filter((v, i, a) => a.indexOf(v) === i)
          urls.forEach(url => {
            str = str.replaceAll(url, `<a href='${url}' target='_blank'>${url}</a>`)
          })
        }

        $('.stip_card-body').append(`
          <div class='stip-mb-3' style='display: -webkit-box;'>
            <div class='stip_chat-img'>
            ` + stip_avatar + `
            </div>
            <div id=` + chat_id + ` class='stip_chat-left stip-align-self-start stip-ml-3'>
              ` + str + `
              <div id=bot_` + chat_id + ` class='bot_btn_box'></div>
            </div>
          </div>
        `)

        // swiper
        const swiper = new Swiper('.stip-swiper', {
          // Navigation arrows
          navigation: {
            nextEl: '.stip-swiper-button-next',
            prevEl: '.stip-swiper-button-prev',
          },
        });

        // hide img se not found
        document.querySelectorAll('img').forEach(function (img) {
          // img
          img.onerror = function () { this.style.display = 'none'; };
        })

        $(".stip_lightbox-img").click(function () {
          $("#modal" + $(this).attr("id")).show()
        })

        $(".stip_lightbox-close").click(function () {
          $("#modal" + $(this).attr("id")).hide()
        })
        $(".stip_lightbox").click(function () {
          $(this).hide()
        })

        if (msg.text.message.attachment && msg.text.message.attachment.payload.text == "Sono in attesa!") { // se il messaggio è di attesa 
          msg.text.message.attachment.payload.buttons.forEach(element => {
            $('#stip_input-append-user').append("<button type='button' payload=" + element.payload + " class='stip-btn stip_action-button stip_input stip_choice-btn stip-align-self-start stip-mt-2 stip-ml-2 mb-2'>" + element.title + "</button>")
          });
        } else { // per qualsiasi tipo di messaggio in arrivo
          if (msg.text.message.attachment && msg.text.message.attachment.payload.buttons && msg.text.message.attachment.payload.template_type == "buttons") { // bottoni
            $("#stip_input-file").hide()
            $(".stip_input").hide()
            $(".stip_input").attr("disabled", false);
            $(".stip_send-box").hide()

            let btn_show = msg.text.message.attachment.payload.text.indexOf("data_postback") >= 0 ? false : true
            /* $.parseHTML(msg.text.message.attachment.payload.text).forEach(function (el) { //togliere bottoni per dedicato a te
              console.log($(el))
              if ($(el).attr("data_postback") != undefined) {
                btn_show = false
              }
            }) */
            if (btn_show) {
              msg.text.message.attachment.payload.buttons.forEach(element => {
                if (element.type == "web_url") {
                  $('#bot_' + chat_id).append("<a href='" + element.url + "' target='_blank' rel='noopener noreferrer' payload='" + element.title + "' class='stip-btn stip_action-button stip-align-self-start stip-mt-2 stip-ml-2 stip-my-2'>" + element.title + "</a>")
                } else {
                  $('#bot_' + chat_id).append("<button type='button' payload='" + element.payload + "' class='stip-btn stip_action-button stip_choice-btn stip-align-self-start stip-mt-2 stip-ml-2 stip-my-2'>" + element.title + "</button>")
                }
              });
            } else {
              $('div[data_postback]').addClass("stip_choice-btn")
              $('div[data_postback]').each(function (el) {
                $(this).attr("payload", el)
              })
              $('div[data_postback]').css("cursor", "pointer")
            }
          } else if (msg.text.message.input_expected && msg.text.message.input_expected[0] == "attachment") { // allegato
            input_type = "file"
            $(".stip_input").hide()
            $(".stip_input").attr("disabled", true);
            $(".stip_send-box").show()
            $("#stip_attach-icon").show()
          } else if (msg.text.message.attachment && msg.text.message.input_expected && msg.text.message.attachment.payload.template_type == "list") {
            input_type = "list"
            $("#stip_input-file").hide()
            $(".stip_input").attr("disabled", false);
            $(".stip_input").val("")
            $(".stip_input").show()
            $(".stip_send-box").show()

            countries = []
            msg.text.message.attachment.payload.buttons.forEach(el => {
              countries.push(el.title)
            });

            // city typeahead
            let city_counter = 0
            $(".stip_input").attr("type", "text")
            $(".stip_input").attr("autocomplete", "off")
            $(".stip_input").click(function () {
              if ($(".stip_input").val() == "" && input_type == "list") {
                $("#stip_city-suggestion").empty()
                for (let i = 0; i < 50; i++) {
                  $("#stip_city-suggestion").show()
                  $("#stip_city-suggestion").append("<p class='stip_suggestion stip_p'>" + countries[i] + "</p>")
                }
              }
              $(".stip_suggestion").click(function (e) {
                $("#stip_city-suggestion").empty()
                $("#stip_city-suggestion").hide()
                $(".stip_input").val($(this).text())
              })
            })
            $(".stip_card-body").click(function () {
              $("#stip_city-suggestion").empty()
              $("#stip_city-suggestion").hide()
            })
            $(".stip_input").on('keyup touchend', function (e) {
              if (countries.length > 0) {
                if (e.keyCode != 38 && e.keyCode != 40) {
                  $("#stip_city-suggestion").empty()
                  countries.forEach(function (el) {
                    let strings = $(".stip_input").val().toLowerCase().split(" ");
                    let strings_is_included = false
                    strings.forEach(function (string) {
                      if (el.toLowerCase().indexOf(string) >= 0 && el.toLowerCase().split(" ")[0].startsWith($(".stip_input").val().toLowerCase().split(" ")[0])) {
                        strings_is_included = true
                      } else {
                        strings_is_included = false
                      }
                    })
                    if ((el.toLowerCase().startsWith($(".stip_input").val().toLowerCase()) || strings_is_included) && $(".stip_input").val() != "") {
                      $("#stip_city-suggestion").show()
                      $("#stip_city-suggestion").append("<p class='stip_suggestion stip_p'>" + el + "</p>")
                    }
                  })
                  if ($(".stip_input").val() == "") {
                    $("#stip_city-suggestion").hide()
                  }
                  $(".stip_suggestion:first-child").addClass("stip_suggestion-selected")
                }
                $(".stip_suggestion").click(function () {
                  $("#stip_city-suggestion").empty()
                  $("#stip_city-suggestion").hide()
                  $(".stip_input").val($(this).text())
                })

                // al click dei tasti direzionali su e giù e all'invio
                if (e.keyCode == 40) {
                  (city_counter < $(".stip_suggestion").length) ? city_counter += 1 : city_counter
                  $(".stip_suggestion:first-child").removeClass("stip_suggestion-selected")
                  $(".stip_suggestion:nth-child(" + (city_counter - 1) + ")").removeClass("stip_suggestion-selected")
                  $(".stip_suggestion:nth-child(" + city_counter + ")").addClass("stip_suggestion-selected")
                  $(".stip_input").val($(".stip_suggestion-selected").text())
                } else if (e.keyCode == 38) {
                  (city_counter > 1) ? city_counter -= 1 : city_counter
                  $(".stip_suggestion:nth-child(" + (city_counter + 1) + ")").removeClass("stip_suggestion-selected")
                  $(".stip_suggestion:nth-child(" + city_counter + ")").addClass("stip_suggestion-selected")
                  $(".stip_input").val($(".stip_suggestion-selected").text())
                }
              }

              $(".stip_suggestion").hover(function () {
                $(this).css("background-color", stip_color);
              }, function () {
                $(this).css("background-color", "white");
              });
            })

            $(".stip_input").focus()
          } else if (msg.text.message.input_expected == "text" || !msg.text.message.input_expected) { // testo normale
            $("#stip_input-file").hide()
            $(".stip_input").attr("disabled", false);
            $(".stip_input").val("")
            $(".stip_input").show()
            $(".stip_send-box").show()
            $(".stip_input").focus()
          }
        }

        // invio del messaggio al click sulla scelta tra i bottoni
        $(".stip_choice-btn").unbind().click(function (e) {
          e.stopPropagation()
          send_last_message = true

          // rimuovi btn e swiper
          $(".stip_choice-btn").remove()
          $(".stip-swiper").remove()

          if ($(this).attr("data_postback") != undefined) { // cambia testo se card dedicato a te
            let target = e.currentTarget.outerHTML
            target.replace('stip_choice-btn', '');
            console.log($(this).find('h3'))
            $(this).hasClass("stip_card-product-horizontal") ? $(".stip_card-body").append("<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'>" + $(this).find('h3').text() + "</p></div>")
              : $(".stip_card-body").append("<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'><div class='stip_card-product-append' data_postback='1' payload='1' style='cursor: pointer; font-size: 16px; background-color: white;'>" + ($(this).attr("data_name") ? $(this).attr("data_name") : e.currentTarget.innerHTML) + "</div></p></div>");
          } else {
            $(".stip_card-body").append("<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'>" + e.target.innerText + "</p></div>");
          }
          colorChange()
          if (!end_thread) {
            $(".stip_input").hide()
            $("#stip_attach-icon").hide()
            $(".stip_send-box").hide()
            $("#stip_restart-button").show()
          } else {
            $("#stip_attach-icon").show()
            $(".stip_input").show()
            $(".stip_send-box").show()
            $("#stip_restart-button").hide()
          }
          let timeStamp = new Date()
          let data_to_send = {
            "id": stip_id,
            "time": timeStamp.getTime(),
            "messaging": [
              {
                "sender": { "id": stip_user_id },
                "recipient": { "id": stip_id },
                "timestamp": timeStamp.getTime(),
                "postback": { "payload": $(this).attr("payload") }
              }
            ]
          }
          //console.log(data_to_send)

          // chiamata di invio messaggio e ricezione risposta
          sendMessage(data_to_send)
        })
        // cookie chat close al click sul link faq
        $("a.stip-btn").click(function (e) {
          e.stopPropagation()
          localStorage.setItem('stip_chat-open_' + stip_id, "close")
        })



      } else { // visualizzazione della history dei messaggi in socket
        cookie_previous_message.push(msg) // push dei messaggi precedenti nell'array
        localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
        $("#stip_restart-button").show()
        if (msg.text.recipient.id == stip_user_id) { // controllo utente
          let str
          if (msg.text.message.attachment) {
            str = "<p class='stip-m-0 stip_p'>" + msg.text.message.attachment.payload.text.replace(/(?:\r\n|\r|\n)/g, '<br>') + "</p>";
          } else if (msg.text.message.text.includes("|restart")) { // se c'è il |restart nel messaggio
            str = "<p class='stip-m-0 stip_p'>" + (chat_lang == "it" ? "Ricomincia" : "Restart") + "</p>";
          } else {
            str = "<p class='stip-m-0 stip_p'>" + msg.text.message.text + "</p>";
          }
          if (!msg.text.message.is_from_customer) {
            $('.stip_card-body').append(`
            <div class='stip-mb-3' style='display: -webkit-box;'>
              <div class='stip_chat-img'>
              ` + stip_avatar + `
              </div>
              <div class='stip_chat-left stip-align-self-start stip-ml-3'>
                ` + str + `
              </div>
            </div>
          `)
          } else if (!str.includes("|start")) { // se c'è il |start nel messaggio non mostrare
            $(".stip_card-body").append(`<div class='stip_chat-right stip-mb-3 stip-align-self-end'>` + str + `</div>`);
          }
        }
        // cookie chat close al click sul link faq
        $("a.stip-btn").click(function (e) {
          e.stopPropagation()
          localStorage.setItem('stip_chat-open_' + stip_id, "close")
        })
      }

      if (!msg.text.message.is_from_customer) {
        if ($('#bot_' + chat_id).height() > $('.stip_card-body').height()) {
          previous_message_length = $('#' + chat_id).height()
          console.log($('#' + chat_id).offset().top)
          $('.stip_card-body').animate({
            scrollTop: $(".stip_card-body")[0].scrollHeight - $('#' + chat_id).height() - 50
          }, "fast");
        } else if (previous_message_length < $('.stip_card-body').height()) {
          $('.stip_card-body').animate({
            scrollTop: $(".stip_card-body")[0].scrollHeight,
          }, "fast");
        } else {
          previous_message_length = 0
        }
      }
      colorChange()

    }
  }

  function startAssistantChat() {
    fetch("https://prod.ai.stipworld.com/openai_assistants/start_chat", {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify({
        "backend_id": stip_user_id,
        "assistant_id": assistant_id
      })
    }).then(response => response.json())
      .then(data => {
        receiveMessage({
          text: {
            message: {
              text: `Ciao sono l'assistente virtuale di ${stip_companyname}, come posso aiutarti?`,
              is_from_customer: false,
            }
          }
        })
      })
  }

  // start chat quando la chat viene aperta o è aperta
  function start() {
    // chiamata di start della conversazione
    let timeStamp = new Date()
    // differenziazione oggetto messagge per brico
    let message
    //console.log(localStorage.getItem(cookie_lang))
    if (store_id == undefined) {
      if (cookie_lang != undefined) {
        message = {
          "text": "|start",
          "lang": Cookies.get(cookie_lang),
          "source": url_string
        }
      } else {
        message = {
          "text": "|start",
          "source": url_string
        }
      }
    } else {
      if (cookie_lang != undefined) {
        message = {
          "text": "|start",
          "store_id": store_id,
          "lang": Cookies.get(cookie_lang),
          "source": url_string
        }
      } else {
        message = {
          "text": "|start",
          "store_id": store_id,
          "source": url_string
        }
      }
    }

    // get di tutti i parametri nell'url
    const params = new URLSearchParams(window.location.search)
    let q_parameters = {};
    for (var value of params.keys()) {
      q_parameters[value] = params.get(value);
    }

    let data_to_send
    if (id_adv) {
      data_to_send = {
        "id": stip_id,
        "time": timeStamp.getTime(),
        "messaging": [
          {
            "q_parameters": q_parameters,
            "id_adv": id_adv, // id_adv per ibl
            "sender": { "id": stip_user_id },
            "recipient": { "id": stip_id },
            "timestamp": timeStamp.getTime(),
            "message": message,
            "cookie_previous_message": (localStorage.getItem("cookie_previous_message_" + stip_id)) ? true : false
          }
        ]
      }
    } else {
      data_to_send = {
        "id": stip_id,
        "time": timeStamp.getTime(),
        "messaging": [
          {
            "q_parameters": q_parameters,
            "sender": { "id": stip_user_id },
            "recipient": { "id": stip_id },
            "timestamp": timeStamp.getTime(),
            "message": message,
            "cookie_previous_message": (localStorage.getItem("cookie_previous_message_" + stip_id)) ? true : false
          }
        ]
      }
    }

    // if ws_connected is false, try to connect again
    function trySendWebhook() {
      if (ws_connected) {
        $.ajax({
          url: "https://bot.stipworld.com/api/web/webhook/",
          type: 'POST',
          crossDomain: true,
          contentType: 'application/json',
          data: JSON.stringify(data_to_send),
          success: function (res) {
            //console.log(res)
            console.log(JSON.parse(localStorage.getItem("cookie_previous_message_" + stip_id)))
            // caricamento della history se presente
            try {
              if (localStorage.getItem("cookie_previous_message_" + stip_id) && res.recover_conversation && !res.one_shot_flow) {
                cookie_previous_message = JSON.parse(localStorage.getItem("cookie_previous_message_" + stip_id))
                for (let i = 0; i < cookie_previous_message.length; i++) {
                  //$("#stip_restart-button").show()
                  if (!cookie_previous_message[i].text.message.is_from_customer) { // messaggio del bot
                    if (i == (cookie_previous_message.length - 1) || cookie_previous_message[i].text.message.show_link) {
                      push_cookie = false
                      receiveMessage(cookie_previous_message[i])
                    } else {
                      let str
                      if (cookie_previous_message[i].text.message.attachment) {
                        str = "<p class='stip-m-0 stip_p'>" + cookie_previous_message[i].text.message.attachment.payload.text.replace(/(?:\r\n|\r|\n)/g, '<br>') + "</p>";
                      } else {
                        str = "<p class='stip-m-0 stip_p'>" + cookie_previous_message[i].text.message.text + "</p>";
                      }
                      $('.stip_card-body').append(`
                <div class='stip-mb-3' style='display: -webkit-box;'>
                  <div class='stip_chat-img'>
                  ` + stip_avatar + `
                  </div>
                  <div class='stip_chat-left stip-align-self-start stip-ml-3'>
                    ` + str + `
                  </div>
                </div>
              `)
                    }
                  } else { // messaggio dell'utente
                    $(".stip_card-body").append(`<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'>` + cookie_previous_message[i].text.message.text + `</p></div>`);
                  }
                }
              }
              colorChange()
            } catch (err) {
              setTimeout(function () {
                cookie_previous_message = []
                localStorage.removetItem("cookie_previous_message_" + stip_id)
                start()
              }, 100)
              console.log("errore")
              console.log(err)
            }
          },
          error: function (err) {
            console.log(err)
            // se va in errore riprova 
            setTimeout(function () {
              start()
            }, 100)
          }
        })
      } else { // if ws_connected is false, try to connect again
        setTimeout(function () {
          trySendWebhook()
        }, 100)
      }
    }
    if (assistant_id) { // se chat AI
      if (localStorage.getItem("cookie_previous_message_" + stip_id)) {
        cookie_previous_message = JSON.parse(localStorage.getItem("cookie_previous_message_" + stip_id))
        for (let i = 0; i < cookie_previous_message.length; i++) {
          //$("#stip_restart-button").show()
          if (!cookie_previous_message[i].text.message.is_from_customer) { // messaggio del bot
            let str = "<p class='stip-m-0 stip_p'>" + cookie_previous_message[i].text.message.text + "</p>"
            $('.stip_card-body').append(`
              <div class='stip-mb-3' style='display: -webkit-box;'>
                <div class='stip_chat-img'>
                ` + stip_avatar + `
                </div>
                <div class='stip_chat-left stip-align-self-start stip-ml-3'>
                  ` + str + `
                </div>
              </div>
            `)
          } else { // messaggio dell'utente
            $(".stip_card-body").append(`<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'>` + cookie_previous_message[i].text.message.text + `</p></div>`);
          }
        }
        colorChange()
      } else {
        startAssistantChat()
      }
    } else {
      trySendWebhook()
    }
  }

  // connessione company e socket
  async function connected() {
    if (ws_connected || assistant_id) { // se connesso in websocket o chat AI
      $('#stip_is-connecting').hide()

      // chiamata per i dati della company se non presenti in locale
      let res
      if (!localStorage.getItem("stip_company_" + stip_id) || new Date().getTime() > JSON.parse(localStorage.getItem("stip_company_" + stip_id)).expiration) {
        const response = await fetch("https://stipworld.com/api/company/page/" + stip_id + "/?channel=web");
        res = await response.json();

        // set dati in local con scadenza
        localStorage.setItem("stip_company_" + stip_id, JSON.stringify({
          data: res,
          expiration: new Date().getTime() + 86400000,
        }))

      } else {
        res = JSON.parse(localStorage.getItem("stip_company_" + stip_id)).data
      }

      basePath = res.company.base_url
      token = res.company.token
      stip_companyname = res.name == "" ? stip_company_name : res.name
      chat_lang = res.lang ? res.lang : "it"

      if (chat_lang !== "it") { // se la lingua impostata è in inglese
        /* $("#stip_restart-button").text("Restart") */
        $("#stip_subtitle").text("Virtual Assistant")
        $("#stip_restart-modal-title").text("Are you sure you want to start over?")
        $("#stip_yesrestart-button").text("yes")
        $(".stip_input").attr("placeholder", "type here ...")
      }

      $("#stip_company-name").text(stip_companyname)
      if (!res.bot_active && !assistant_id) {
        $("#stip_restart-button").remove()
      } else {
        $("#stip_restart-button").show()
      }
      if (res.one_shot_flow) {
        $("#stip_restart-button").remove()
      } else {
        $("#stip_restart-button").show()
      }

      // aggiungi privacy iframe e mostra icona
      if (res.privacy_url) {
        $("#stip_privacy-btn").show()
        $("#stip_privacy-iframe").attr("src", res.privacy_url)
      }

      // personalizzazione da chiamata company
      let data = res.styles
      if (data) {
        for (const key in data) {
          if (Object.hasOwnProperty.call(data, key) && data[key]) {
            const val = data[key];
            eval(`${key} = '${val}';`);
          }
        }
        if (data.stip_chat_icon) {
          $(".stip_chat-icon").remove()
          $("#stip_chat_icon_box").append('<img src="' + data.stip_chat_icon + '" class="stip_chat-icon" style="width:40px">')
        }
        if (data.stip_avatar_header) {
          $(".stip_avatar").remove()
          $("#stip_avatar_header_box").append("<div class='stip_avatar' style='background: url(" + data.stip_avatar_header + ") no-repeat center center; background-size: contain;'></div>")
        }
        if (data.stip_avatar) stip_avatar = "<div class='stip_avatar' style='background: url(" + data.stip_avatar + ") no-repeat center center; background-size: contain;'></div>"
      }

      // personalizza chat e show chat
      if ((res.bot_active && chat_show) || assistant_id) { // se la chat è attiva e visibile o è una chat AI
        colorChange()
        $("#stip_chat").show()
      }

    } else {
      console.log("ws non connessa o in connessione")
      $('#stip_is-connecting').show()
      setTimeout(function () {
        connected()
      }, 100)
    }
  }
  connected()

  function noConnection() {
    ws_connected = false
    $(".stip_choice-btn").attr("disabled", true)
    $(".stip_choice-btn").addClass("stip_btn_disabled")
  }

  function yesConnection() {
    $(".stip_choice-btn").attr("disabled", false)
    $(".stip_choice-btn").removeClass("stip_btn_disabled")
  }

  let RECONNECT_INTERVAL = 2000;
  let ws_connected = false
  let chats = null;
  let ws_url = "wss://bot.stipworld.com/ws-new/" + stip_user_id + "/"

  function connectSocket(url) {

    let fbSubject = fbSubject = webSocket({
      url: url,
      deserializer: msg => msg,
      openObserver: {
        next: (val) => {
          console.log('ws connessa');
          ws_connected = true
          yesConnection()
        }
      }
    });

    console.debug("Successfully connected Chats: " + url);
    return fbSubject;
  }

  function wsChats() {

    if (!chats && !assistant_id) { // se non è chat AI e non è presente una connessione
      if (localStorage.getItem('stip_chat-open_' + stip_id) == 'open') {
        $(".stip_input").show()
      }

      chats = connectSocket(ws_url).asObservable().pipe(
        map((response) => {
          if (!response)
            return
          if (response.data == "--heartbeat--")
            return
          let data = JSON.parse(response.data);
          return data
        }),
        retryWhen(errors => errors.pipe(tap(val => {
          console.log('[Data Service] Try to reconnect chats');
          noConnection()
        }),
          delayWhen(_ => timer(RECONNECT_INTERVAL)))))
        .subscribe(data => {
          //console.log('chat mess', data)
          if (data == undefined) {

            return;
          }
          receiveMessage(data)
        });
    }
  }

  $("#stip_start-btn").hide()
  $("#stip_skip-button").hide()
  if (!assistant_id) { // if not assistant chat
    $("#stip_restart-button").hide()
  }
  if (localStorage.getItem('stip_chat-open_' + stip_id) == 'close' || !localStorage.getItem('stip_chat-open_' + stip_id)) {
    $(".stip_input").hide()
  }
  $("#stip_city-suggestion").hide()
  $("#stip_attach-icon").click(function () {
    input_type = "file"
    $("#stip_input-file").trigger('click');
  });
  $("#stip_input-file").change(function () {
    if ($("#stip_input-file").val() != "") {
      $("#stip_chat_form").submit()
    }
  })

  // hide welcome message DAT se mobile
  if ((stip_id == "64c695e1aae2" || stip_id == "5e1aae264c62") && window.innerWidth < 768) {
    $(".stip_welcome-message").hide()
  }
  /* setTimeout(function () {
    $(".stip_welcome-message").hide()
  }, 5000) */

  // hide show della chat
  if (stip_hide_chat) {
    var date = new Date();
    var hour = date.getHours();
    if ((hour > 18 || hour < 9) && stip_id != "brico") {
      chat_show = false
    } /* else if ((hour > 17 || hour < 9) && stip_id == "brico") {
      chat_show = false
    }  */else {
      wsChats() // inizializzazione socket
      chat_show = true
    }
  } else if (stip_id == "b58bd00f-b8a2-4f06-85f4-5e1aae264c62") {
    var date = new Date();
    var hour = date.getHours();
    var days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    var festivity = ['1/1', '6/1', '25/4', '1/5', '2/6', '15/8', '1/11', '8/12', '25/12', '26/12']; // festività generiche fisse
    var festivity_easter = ['17/4/2022', '18/4/2022', '9/4/2023', '10/4/2023', '31/3/2024', '1/4/2024']; // pasqua fino a 2023
    var day = days[date.getDay()];
    var day_festivity = date.getUTCDate();
    var month_festivity = date.getUTCMonth() + 1; //months from 1-12
    var year_festivity = date.getUTCFullYear();
    var newdate_festivity = day_festivity + "/" + month_festivity;
    var newdate_festivity_easter = day_festivity + "/" + month_festivity + "/" + year_festivity;
    var festivity_check = false
    festivity.forEach(function (el) {
      if (newdate_festivity == el) {
        festivity_check = true
      }
    })
    festivity_easter.forEach(function (el) {
      if (newdate_festivity_easter == el) {
        festivity_check = true
      }
    })
    if (hour > 17 || hour < 9 || day == 'Sunday' || day == 'Saturday' || festivity_check) { // se l'orario è dopo le 18 o è weekend nascondi
      chat_show = false
    } else {
      wsChats() // inizializzazione socket
      chat_show = true
    }
  } else {
    wsChats() // inizializzazione socket
    chat_show = true
  }

  if (Cookies.get(cookie_lang) == "en") { // se la lingua impostata è in inglese
    /* $("#stip_restart-button").text("Restart") */
    $("#stip_subtitle").text("Virtual Assistant")
    $("#stip_restart-modal-title").text("Are you sure you want to start over?")
    $("#stip_yesrestart-button").text("yes")
    $(".stip_input").attr("placeholder", "type here ...")
  }

  // PERSONALIZZAZIONI
  function colorChange() {
    $(".stip_card").css("background-color", stip_color)
    if (url_string.indexOf("dedicato") > 0) { // font per dedicato a te
      $("#stip_chat *:not(.material-icons)").css("font-family", "Arial, sans-serif")
    }
    $(".stip_chat-right p").css("color", stip_user_text_color)
    if (!stip_secondary_color) {
      $(".stip_send-icon").css("color", stip_color)
      $(".stip_action-button").css({
        "background-color": "transparent",
        "color": (stip_bot_text_color) ? stip_bot_text_color : stip_color,
        "border": "1px solid " + stip_color
      })
      $(".stip_action-button").hover(function () {
        $(this).css({
          "background-color": stip_color,
          "color": "white"
        })
      }, function () {
        $(this).css({
          "background-color": "transparent",
          "color": (stip_bot_text_color) ? stip_bot_text_color : stip_color
        })
      })
      $(".stip_card-product").hover(function () {
        $(this).css({
          "background-color": stip_color,
        })
        $(this).find("p,h1,h2,h3").css("color", "white")
      }, function () {
        $(this).css({
          "background-color": "white",
        })
        $(this).find("p,h1,h2,h3").css({
          "color": (stip_bot_text_color) ? stip_bot_text_color : stip_color
        })
      })
      $("#stip_skip-button").css({
        "background-color": stip_color
      })
      $("#stip_restart-button").css({
        "background-color": stip_color
      })
      $(".stip_chat-right").css("background-color", stip_color)
      $(".stip_chat-right").find("p,h1,h2,h3,h4").css({
        "color": "white"
      })
      $(".stip_card-product-append").find("p,h1,h2,h3,h4").css({
        "color": stip_color
      })
    } else {
      $(".stip_send-icon").css("color", stip_secondary_color)
      $(".stip_action-button").css({
        "border": "1px solid " + stip_secondary_color
      })
      $(".stip_card-product").find("p,h1,h2,h3").css("color", stip_secondary_color)
      $(".stip_close-btn").css("background-color", stip_secondary_color)
      $(".stip_action-button").hover(function () {
        $(this).css({
          "background-color": stip_secondary_color,
          "color": stip_color
        })
      }, function () {
        $(this).css({
          "background-color": "transparent",
          "color": stip_secondary_color
        })
      })
      $("#stip_title").css({
        "color": stip_secondary_color
      })
      $("#stip_subtitle").css({
        "color": stip_secondary_color
      })
      $(".stip_action-button").css({
        "color": stip_secondary_color
      })
      $("#stip_skip-button").css({
        "background-color": stip_secondary_color,
        "color": stip_color
      })
      $("#stip_restart-button").css({
        "background-color": stip_secondary_color,
        "color": stip_color
      })
      $(".stip_chat-right").css({
        "background-color": stip_secondary_color,
        "color": stip_color
      })
    }
    $(".stip_chat-left").css({
      "background-color": stip_bot_box_color,
      "color": stip_bot_text_color
    })
    if (getUrlParameter(url_string, "stip_chat-fixed") == true || this_script.attr('stip_chat-fixed') == true) {
    } else {
      $(".bot_btn_box").css({
        "display": "flex",
        "flex-wrap": "wrap"
      })
    }
    $(".stip_input").focus(function () {
      $(this).css({
        "border-color": stip_color
      })
    })
    $(".stip_input").focusout(function () {
      $(this).css({
        "border-color": "rgb(206, 212, 218)"
      })
    })
    $(".stip_card-header").css("background-color", stip_color)
    if (stip_color_header) {
      $(".stip_card-header").css("background-color", stip_color_header)
      $(".stip_card-header p").css("color", "white")
    }
    $(".stip_close-btn").css({
      "color": stip_color
    })

    if (getUrlParameter(url_string, "stip_chat-fixed") == true || this_script.attr('stip_chat-fixed') == true) { // versione chat fixed
      $(".stip_h1").css("font-size", "18px")
      $(".stip_p").css("font-size", "16px")
      $(".stip_label").css("font-size", "14px")
      $(".stip_choice-btn").css("font-size", "16px")
      /* $("#stip_restart-button").css("font-size", "14px") */
      $("#stip_skip-button").css("font-size", "14px")
      $(".stip_input").css("font-size", "16px")
      if ($(window).width() < 576) {
        $(".stip_avatar").css({
          "width": "35px",
          "height": "35px"
        })
      }
      if (stip_background) {
        $(".stip_card-body").css({
          "background": "url(" + stip_background + ") center center no-repeat",
          "background-size": "cover"
        })
      }
    }
  }
  colorChange()

  if (url_string.indexOf("stip.io") > 0 && getUrlParameter(url_string, "stip_chat-fixed") != true) {
    $("#stip_chat").css("height", "600px")
    $("#stip_chat").css("margin-top", "60px")
  }

  let firstOpen = true // chiamata di start all'open della chat
  if (getUrlParameter(url_string, "stip_chat-fixed") == true || this_script.attr('stip_chat-fixed') == true) {
    $("#stip_chat").addClass("stip_chat-fixed")
    $(".stip_card").css("background-color", stip_color)
    $(".stip_card").addClass("stip_card-fixed")
    $(".stip_card-header").hide()
    $(".stip_card-body").hide()
    $(".stip_card-footer").hide()
    let chatOpen = false // controllo per evitare il ripetersi degli eventi al click sulla card
    $("#stip_chat").click(function () {
      if (firstOpen && localStorage.getItem('stip_chat-open_' + stip_id) == 'close') {
        // hide welcome message
        $(".stip_welcome-message").hide()

        start()
        $(".stip_input").show()
        firstOpen = false
      }
      localStorage.setItem('stip_chat-open_' + stip_id, "open");
      $(".stip_notification").hide()
      if (!chatOpen) {
        $("#stip_chat").addClass("stip_chat-fixed-open")
        $(".stip_card").removeClass("stip_card-fixed")
        $(".stip_card").css("background-color", "white")
        setTimeout(function () {
          $(".stip_card-body").show()
          $(".stip_card-footer").show()
          $('.stip_card-body').animate({
            scrollTop: $(".stip_card-body")[0].scrollHeight,
          }, "fast");
        }, 300)
        $(".stip_card-header").show()
        $('.stip_chat-icon').hide()
        if (this_script.attr('stip_toggleChatbot')) {
          var event = new CustomEvent("toggle", { // open event
            detail: {
              open: true
            }
          });
          let functionName = this_script.attr('stip_toggleChatbot')
          window[functionName](event)
        }
        chatOpen = true
        if (stip_height && $(window).width() > 576) {
          $(".stip_chat-fixed-open").css("height", stip_height + "px")
        }
      }
    })

    $(".stip_close-btn").click(function (e) {
      localStorage.setItem('stip_chat-open_' + stip_id, "close");
      e.stopPropagation();
      chatOpen = false
      if (stip_height && $(window).width() > 576) {
        $(".stip_chat-fixed-open").css("height", "65px")
      }

      $("#stip_chat").removeClass("stip_chat-fixed-open")
      $(".stip_card").addClass("stip_card-fixed")
      $(".stip_card").css("background-color", stip_color)
      $(".stip_card-header").hide()
      $(".stip_card-body").hide()
      $(".stip_card-footer").hide()
      $('.stip_chat-icon').show()
      if (this_script.attr('stip_toggleChatbot')) {
        var event = new CustomEvent("toggle", { // close event
          detail: {
            open: false
          }
        });
        let functionName = this_script.attr('stip_toggleChatbot')
        window[functionName](event)
      }
    })
    if (url_string.indexOf("stip.io") > 0 || url_string.indexOf("dedicato") > 0) { // font per stip.io e dedicato a te
      $("#stip_chat *:not(.material-icons)").css("font-family", "Arial, sans-serif")
    }
  } else {
    start()
    if (url_string.indexOf("stip.io") > 0 || url_string.indexOf("dedicato") > 0) { // font per stip.io e dedicato a te
      $("#stip_chat *:not(.material-icons)").css("font-family", "Arial, sans-serif")
    }
    $(".stip_close-btn").hide()
    $('.stip_chat-icon').hide()
  }

  // se la chat è aperta è l'utente cambia pagina o refresha la chat si riapre
  if (localStorage.getItem('stip_chat-open_' + stip_id) == "open" && (getUrlParameter(url_string, "stip_chat-fixed") == true || this_script.attr('stip_chat-fixed') == true)) {
    firstOpen = false
    start()
    $("#stip_chat").addClass("stip_chat-fixed-open")
    $(".stip_card").removeClass("stip_card-fixed")
    $(".stip_card").css("background-color", "white")
    $(".stip_card-header").show()
    $(".stip_card-body").show()
    $(".stip_card-footer").show()
    $('.stip_chat-icon').hide()
    if (stip_height && $(window).width() > 576) {
      $(".stip_chat-fixed-open").css("height", stip_height + "px")
    }
    setTimeout(function () {
      $('.stip_card-body').animate({
        scrollTop: $(".stip_card-body")[0].scrollHeight,
      }, "fast");
    }, 300)
  } else {
    localStorage.setItem('stip_chat-open_' + stip_id, 'close');
  }

  $("#stip_restart-modal").hide()
  // chiamata di restart della chat 
  $("#stip_restart-button").click(function () {
    $("#stip_restart-modal").show()
  })
  $("#stip_yesrestart-button").click(function () {
    cookie_previous_message = []
    $("#input-error").remove()
    $(".stip_card-body").empty()

    if (assistant_id) { // restart chat AI
      // remove previous message
      localStorage.removeItem("cookie_previous_message_" + stip_id)
      // reset user id
      stip_user_id = uuid.v4()
      localStorage.setItem('stip_user_id_' + stip_id, stip_user_id);
      startAssistantChat()
    } else {
      let timeStamp = new Date()
      // differenziazione oggetto messagge per brico
      let message
      if (store_id == undefined) {
        if (cookie_lang != undefined) {
          message = {
            "text": "|restart",
            "lang": Cookies.get(cookie_lang)
          }
        } else {
          message = {
            "text": "|restart"
          }
        }
      } else {
        if (cookie_lang != undefined) {
          message = {
            "text": "|restart",
            "store_id": store_id,
            "lang": Cookies.get(cookie_lang)
          }
        } else {
          message = {
            "text": "|restart",
            "store_id": store_id
          }
        }
      }

      let data_to_send = {
        "id": stip_id,
        "time": timeStamp.getTime(),
        "messaging": [
          {
            "sender": { "id": stip_user_id },
            "recipient": { "id": stip_id },
            "timestamp": timeStamp.getTime(),
            "message": message
          }
        ]
      }
      // chiamata di invio messaggio e ricezione risposta
      sendMessage(data_to_send)
    }

    $("#stip_restart-modal").hide()
  })
  $("#stip_norestart-button").click(function () {
    $("#stip_restart-modal").hide()
  })

  // hide/show privacy
  $("#stip_privacy-btn").click(function () {
    $("#stip_privacy-modal").show()
  })
  $("#stip_privacy-modal i").click(function () {
    $("#stip_privacy-modal").hide()
  })
  $("#stip_privacy-modal").not("iframe").click(function () {
    $("#stip_privacy-modal").hide()
  })

  // chiamata di skip
  $("#stip_skip-button").click(function () {
    let timeStamp = new Date()
    let data_to_send = {
      "id": stip_id,
      "time": timeStamp.getTime(),
      "messaging": [
        {
          "sender": { "id": stip_user_id },
          "recipient": { "id": stip_id },
          "timestamp": timeStamp.getTime(),
          "message": { "text": "|skip" }
        }
      ]
    }

    // chiamata di invio messaggio e ricezione risposta
    sendMessage(data_to_send)
    $("#stip_skip-button").hide()
    $(".stip_card-body").append("<div class='stip_chat-right stip-mb-3 stip-align-self-end'><p class='stip-m-0 stip_p'>salta</p></div>");
    send_last_message = true
    $('.stip_card-body').animate({
      scrollTop: $(".stip_card-body")[0].scrollHeight,
    }, "fast");
  })

  function afterSubmit(attach_url) {
    if (input_type == "") {
      if (!end_thread) {
        $(".stip_input").hide()
        $("#stip_attach-icon").hide()
        $(".stip_send-box").hide()
        $("#stip_restart-button").show()
      } else {
        $(".stip_input").show()
        $("#stip_attach-icon").show()
        $(".stip_send-box").show()
        $("#stip_restart-button").hide()
      }
      let str
      let img_extension = [".jpg", ".png", ".jpeg", ".svg", ".bmp", ".webp", ".gif"]
      let extension_check = false
      img_extension.forEach(function (el) {
        if (attach_url && attach_url.indexOf(el) > 0) {
          extension_check = true
        }
      })
      if (extension_check) {
        let random_id = Math.floor(Math.random() * 1000)
        str = (`
          <img src=` + attach_url + ` style="cursor: pointer;" class="stip_lightbox-img" id="` + random_id + `" width="100">
          <div id="modal` + random_id + `" class="stip_lightbox" style="display: none">
            <div class="stip_lightbox-dialog">
              <div class="stip_lightbox-content">
                <i class="material-icons stip_lightbox-close" style="color: ` + stip_color + `" id="` + random_id + `">close</i>
                <img src=` + attach_url + ` style="max-width: 100%">
              </div>
            </div>
          </div>
        `)
      } else if (attach_url && !extension_check) {
        str = '<a style="color: white" href=' + attach_url + ' target="_blank">Allegato</a>'
      } else {
        str = "<p class='stip-m-0 stip_p'>" + $('.stip_input').val() + "</p>"
      }
      $(".stip_card-body").append("<div class='stip_chat-right stip-mb-3 stip-align-self-end'>" + str + "</div>");
      send_last_message = true
      $(".stip_lightbox-img").click(function () {
        $("#modal" + $(this).attr("id")).show()
      })

      $(".stip_lightbox-close").click(function () {
        $("#modal" + $(this).attr("id")).hide()
      })
      $(".stip_lightbox").click(function () {
        $(this).hide()
      })
      $(".stip_input").val("")
      $("#stip_input-file").val("");
      colorChange()
      // chiamata di invio messaggio e ricezione risposta
      sendMessage(data_to_send)
    }
    $('.stip_card-body').animate({
      scrollTop: $(".stip_card-body")[0].scrollHeight,
    }, "fast");
  }

  let data_to_send
  $("#stip_chat_form").submit(function (e) {
    e.preventDefault()

    if (input_type == "file") {
      $(".stip_send-box").hide()
      //chiamata di upload se l'input type è file
      let file = $("#stip_input-file")[0].files[0]
      let file_type = ""
      if ($("#stip_input-file")[0].files[0].type.indexOf("video") >= 0) {
        file_type = "video"
      } else if ($("#stip_input-file")[0].files[0].type.indexOf("image") >= 0) {
        file_type = "image"
      } else {
        file_type = "file"
      }
      let formData = new FormData()
      formData.append('file', file);
      $.ajax({
        url: basePath + "/api/upload/",
        headers: {
          Authorization: "Token " + token
        },
        processData: false,
        contentType: false,
        type: 'POST',
        data: formData,
        success: function (res) {
          //console.log(res)
          let timeStamp = new Date()
          data_to_send = {
            "id": stip_id,
            "time": timeStamp.getTime(),
            "messaging": [
              {
                "sender": { "id": stip_user_id },
                "recipient": { "id": stip_id },
                "timestamp": timeStamp.getTime(),
                "message": { "attachments": [{ "payload": { "url": basePath + res }, "type": file_type }] }
              }
            ]
          }
          input_type = ""
          //console.log(data_to_send)
          afterSubmit(basePath + res)
        },
        error: function (err) {
          console.log(err)
        }
      })
    } else if (input_type == "list") {
      $("#input-error").remove()
      let inputcheck = false
      countries.forEach(element => {
        if (element.toLowerCase() == $('.stip_input').val().toLowerCase()) {
          inputcheck = true
        }
      });
      if (!inputcheck) {
        $("#stip-form-group").append("<label class='stip_label' id='input-error' style='color: red;'>scegli una città valida</label>")
      } else {
        input_type = ""
        $("#input-error").remove()
        countries = []
        $("#stip_city-suggestion").hide()
        $("#stip_city-suggestion").empty()
        let timeStamp = new Date()
        data_to_send = {
          "id": stip_id,
          "time": timeStamp.getTime(),
          "messaging": [
            {
              "sender": { "id": stip_user_id },
              "recipient": { "id": stip_id },
              "timestamp": timeStamp.getTime(),
              "message": { "text": $(".stip_input").val() }
            }
          ]
        }
        afterSubmit()
        //console.log(data_to_send)
      }
    } else {
      let timeStamp = new Date()
      data_to_send = {
        "id": stip_id,
        "time": timeStamp.getTime(),
        "messaging": [
          {
            "sender": { "id": stip_user_id },
            "recipient": { "id": stip_id },
            "timestamp": timeStamp.getTime(),
            "message": { "text": $(".stip_input").val() }
          }
        ]
      }
      afterSubmit()
      //console.log(data_to_send)
    }
  })

  //chiamata alla chiusura della tab per comunicare all'operatore che la chat non è più attiva
  /* window.addEventListener("unload", logData, false);
  function logData() {
    //localStorage.setItem("cookie_previous_message_" + stip_id, JSON.stringify(cookie_previous_message))
    if (navigator.sendBeacon) {
      var blob = new Blob([JSON.stringify({ "social_id": stip_user_id, "page_id": stip_id })], { type: 'application/json; charset=UTF-8' }); // the blob
      navigator.sendBeacon("https://bot.stipworld.com/api/users/offline/", blob)
    } else {
      let data_to_send = {
        "social_id": stip_user_id,
        "page_id": stip_id
      }
      $.ajax({
        url: "https://bot.stipworld.com/api/users/offline/",
        type: 'POST',
        crossDomain: true,
        contentType: 'application/json',
        data: JSON.stringify(data_to_send),
        success: function (res) {
          //console.log(res)
        },
        error: function (err) {
          console.log(err)
        }
      })
    }
  } */
})

export function userData(data) {
  let this_script = $("script[src*='index.bundle.js']");
  let data_to_send = data
  console.log("stip data recived: ")
  console.log(data)
  let id_append = (getUrlParameter(url_string, "stip_id")) ? getUrlParameter(url_string, "stip_id") : this_script.attr('stip_id')
  data.stip_user_id = localStorage.getItem('stip_user_id_' + id_append)
  data.page_id = (getUrlParameter(url_string, "stip_id")) ? getUrlParameter(url_string, "stip_id") : this_script.attr('stip_id')
  $.ajax({
    url: "https://stipworld.com/api/users/data/",
    type: 'POST',
    contentType: "application/json",
    type: 'POST',
    data: JSON.stringify(data_to_send),
    success: function (res) {
      //console.log(res)
    },
    error: function (err) {
      console.log(err)
    }
  })
}

export function openChat() { // apri chat o modal chat offline
  let this_script = $("script[src*='index.bundle.js']");
  let stip_hide_chat = (getUrlParameter(url_string, "stip_hide_chat")) ? getUrlParameter(url_string, "stip_hide_chat") : this_script.attr('stip_hide_chat')
  let stip_id = (getUrlParameter(url_string, "stip_id")) ? getUrlParameter(url_string, "stip_id") : this_script.attr('stip_id')
  if (stip_hide_chat) {
    var date = new Date();
    var hour = date.getHours();
    /* if ((hour > 17 || hour < 9) && stip_id == "brico") {
      $("#stip_offline-modal").show()
      $("#stip_offline-button, #stip_offline-modal").click(() => {
        $("#stip_offline-modal").hide()
      })
    } else */ if ((hour > 18 || hour < 9) && stip_id != "brico") {
      $("#stip_offline-modal").show()
      $("#stip_offline-button, #stip_offline-modal").click(() => {
        $("#stip_offline-modal").hide()
      })
    } else {
      document.getElementById('stip_chat').click();
    }
  }
}